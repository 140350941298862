<template>
  <div class="appointDetail">
    <div class="areaAppointItem">
      <div class="appointList-t">
        <div class="appointList-tl">
          <img :src="detailData.picUrl" alt="" />
        </div>
        <div class="appointList-tr">
          <div class="name">{{ detailData.placeName }}</div>
          <div class="address">{{ detailData.address }}</div>
        </div>
        <div class="status">{{ getStatus(detailData) }}</div>
      </div>
      <div class="appointList-b">
        <div class="order">
          <div class="item-l">工单号：</div>
          <div class="item-r">{{ detailData.id }}</div>
        </div>
        <div class="orderTime">
          <div class="item-l">下单时间：</div>
          <div class="item-r">{{ detailData.referTime }}</div>
        </div>
        <div class="times">
          <div class="item-l">预约场次：</div>
          <div class="item-r">
            <div
              class="scheduleList"
              v-for="(oItem, oIndex) in detailData.scheduleList"
              :key="oIndex"
            >
              <p class="p-l">{{ oItem.strDateTime }}</p>
              <p class="p-r">{{ oItem.strTimeStatus }}</p>
            </div>
          </div>
        </div>
        <div class="remark" v-if="detailData.remark">
          <div class="item-l">预约说明：</div>
          <div class="item-r">{{ detailData.remark }}</div>
        </div>
        <div class="order" v-if="type==2">
          <div class="item-l">社群名称：</div>
          <div class="item-r">{{ detailData.orderAssociationName }}</div>
        </div>
      </div>
      <div class="teamPoints" v-if="type==2" @click="toPath('teamPoints')">查看团队积分获得情况</div>
    </div>
    <div class="opera" @click="cancel" v-if="detailData.cancelBook">
      取消订单
    </div>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <p style="text-align: center; line-height: 40px">是否确认取消订单？</p>
    </v-dialog>
  </div>
</template>

<script>
import { myAppointDetailUrl, cancelAppointUrl } from "./api.js";
import { appointStatus } from "./map.js";
import { getOption } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "appointDetail",
  data() {
    return {
      timer: null,
      orderId: "",
      detailData: {},
      isDialog: false,
      type:null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.type = this.$route.query.type
  },
  mounted() {
    this.getMyAppointDetail();
  },
  methods: {
    toPath(name){
      this.$router.push({
        name:name,
        query:{
          orderId:this.detailData.id,
          associationId:this.detailData.associationId
        }        
      })
    },
    cancel() {
      this.isDialog = true;
    },
    confirm() {
      let params = {
        orderId: this.orderId,
      };
      this.$axios
        .post(`${cancelAppointUrl}?orderId=${this.orderId}`)
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "取消成功", duration: 300 });
            this.timer = setTimeout(() => {
              this.$router.replace({
                name: "appointList",
              });
            }, 400);
          } else {
          }
        });
    },
    getStatus(item) {
      if (item.status) {
        console.log(
          88,
          item,
          getOption(item.status, appointStatus, "key").value
        );
        return getOption(item.status, appointStatus, "key").value;
      } else {
        return "";
      }
    },

    getMyAppointDetail() {
      let params = {
        orderId: this.orderId,
      };
      this.$axios.get(`${myAppointDetailUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.detailData = res.data;
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.appointDetail {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f7f7f7;
  padding-bottom: 120px;
  .areaAppointItem {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
  }
  .teamPoints {
    width: 100%;
    height: 152px;
    line-height: 152px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #007EFF;
    text-align: center;
  }
  .appointList-t {
    display: flex;
    position: relative;
    padding: 20px 0;
    border-bottom: 1px solid #e7e7e7;
    .appointList-tl {
      width: 200px;
      height: 200px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .appointList-tr {
      width: 440px;
      padding: 20px 0;
      font-size: 30px;
      .name,
      .address {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .address {
        margin: 20px 0;
      }
    }
    .status {
      position: absolute;
      font-size: 28px;
      color: #009aff;
      top: 30px;
      right: 10px;
    }
  }
  .appointList-b {
    padding: 20px 0;
    .order,
    .orderTime,
    .times {
      margin: 10px 0;
      display: flex;
      font-size: 28px;
      .item-l {
        width: 200px;
      }
      .item-r {
        flex: 1;
        color: #999;
      }
    }
    .times {
      .scheduleList {
        display: flex;
        justify-content: space-between;
      }
    }
    .remark {
      font-size: 28px;
      display: flex;
      .item-l {
        width: 200px;
      }
      .item-r {
        flex: 1;
        color: #999;
      }
    }
  }
  .opera {
    width: 100%;
    left: 0;
    bottom: 0;
    position: fixed;
    background: #999;
    color: #fff;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    text-align: center;
  }
}
</style>
